import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';

export default function TermsAndConditions() {
  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.600', 'gray.300');

  return (
    <Box bg={useColorModeValue('gray.50', 'gray.900')} minH="100vh" py={10}>
      <Container maxW="container.md">
        <VStack spacing={6} align="stretch" bg={bgColor} p={8} borderRadius="lg" boxShadow="sm">
          <Heading as="h1" size="xl">Terms and Conditions</Heading>
          
          <Text color={textColor}>Last updated: {new Date().toLocaleDateString()}</Text>

          <VStack spacing={4} align="stretch">
            <Heading as="h2" size="lg">1. Agreement to Terms</Heading>
            <Text color={textColor}>
              By accessing or using Null-ID's services, you agree to be bound by these Terms and Conditions.
              If you disagree with any part of these terms, you may not access the service.
            </Text>

            <Heading as="h2" size="lg">2. Use License</Heading>
            <Text color={textColor}>
              We grant you a limited, non-exclusive, non-transferable, revocable license to use our
              service strictly in accordance with these terms and any additional terms specific to your
              subscription plan.
            </Text>

            <Heading as="h2" size="lg">3. Service Usage</Heading>
            <Text color={textColor}>
              You agree not to:
              - Use the service for any unlawful purpose
              - Attempt to gain unauthorized access to our systems
              - Interfere with or disrupt the service
              - Resell or redistribute the service without authorization
            </Text>

            <Heading as="h2" size="lg">4. Intellectual Property</Heading>
            <Text color={textColor}>
              The service and its original content, features, and functionality are owned by Null-ID
              and are protected by international copyright, trademark, patent, trade secret, and other
              intellectual property laws.
            </Text>

            <Heading as="h2" size="lg">5. Termination</Heading>
            <Text color={textColor}>
              We may terminate or suspend your access to our service immediately, without prior notice
              or liability, for any reason whatsoever, including without limitation if you breach the Terms.
            </Text>

            <Heading as="h2" size="lg">6. Contact Us</Heading>
            <Text color={textColor}>
              If you have any questions about these Terms, please contact us at: privacy@null-id.com
            </Text>
          </VStack>
        </VStack>
      </Container>
    </Box>
  );
}
