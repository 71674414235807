import { useState } from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  Flex,
  Code,
  OrderedList,
  ListItem,
  Alert,
  AlertIcon,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  useColorModeValue,
} from '@chakra-ui/react';
import { DocSidebar } from '../components/Documentation/DocSidebar';

// Documentation sections
const QuickStartSection = () => (
  <VStack align="stretch" spacing={4}>
    <Heading size="lg">Quick Start Guide</Heading>
    <Text>Get started with Null-ID in minutes. Follow these steps to begin anonymizing your data:</Text>
    <OrderedList spacing={4} pl={4}>
      <ListItem>
        <Text fontWeight="bold">Install the package</Text>
        <Code p={2} display="block" whiteSpace="pre" my={2}>
          npm install @null-id/core
        </Code>
      </ListItem>
      <ListItem>
        <Text fontWeight="bold">Initialize Null-ID</Text>
        <Code p={2} display="block" whiteSpace="pre" my={2}>
          {`import { NullID } from '@null-id/core';

const nullId = new NullID({
  apiKey: 'your-api-key',
  region: 'us-west-2'
});`}
        </Code>
      </ListItem>
      <ListItem>
        <Text fontWeight="bold">Create an anonymization policy</Text>
        <Code p={2} display="block" whiteSpace="pre" my={2}>
          {`const policy = {
  fields: {
    email: 'hash',
    phone: 'mask',
    address: 'redact'
  }
};`}
        </Code>
      </ListItem>
      <ListItem>
        <Text fontWeight="bold">Anonymize your data</Text>
        <Code p={2} display="block" whiteSpace="pre" my={2}>
          {`const result = await nullId.anonymize(data, policy);
console.log(result);`}
        </Code>
      </ListItem>
    </OrderedList>
  </VStack>
);

const ConfigurationSection = () => (
  <VStack align="stretch" spacing={4}>
    <Heading size="lg">Configuration</Heading>
    <Text>
      Null-ID offers extensive configuration options to customize the anonymization process
      according to your needs.
    </Text>
    
    <Tabs variant="enclosed">
      <TabList>
        <Tab>Basic Config</Tab>
        <Tab>Advanced Options</Tab>
        <Tab>Compliance Presets</Tab>
      </TabList>

      <TabPanels>
        <TabPanel>
          <VStack align="stretch" spacing={4}>
            <Text>Essential configuration options to get started:</Text>
            <Code p={4} display="block" whiteSpace="pre">
              {`{
  apiKey: 'your-api-key',
  region: 'us-west-2',
  defaultPolicy: 'strict',
  logging: true
}`}
            </Code>
          </VStack>
        </TabPanel>
        <TabPanel>
          <VStack align="stretch" spacing={4}>
            <Text>Advanced configuration for fine-tuned control:</Text>
            <Code p={4} display="block" whiteSpace="pre">
              {`{
  customPatterns: {
    creditCard: /\\d{4}-\\d{4}-\\d{4}-\\d{4}/,
    ssn: /\\d{3}-\\d{2}-\\d{4}/
  },
  transformers: {
    // Custom transformation functions
    myTransformer: (value) => transform(value)
  },
  validation: {
    strict: true,
    throwOnError: false
  }
}`}
            </Code>
          </VStack>
        </TabPanel>
        <TabPanel>
          <VStack align="stretch" spacing={4}>
            <Text>Pre-configured settings for common compliance requirements:</Text>
            <Alert status="info">
              <AlertIcon />
              These presets are regularly updated to reflect the latest regulatory requirements.
            </Alert>
            <Code p={4} display="block" whiteSpace="pre">
              {`// GDPR Configuration
const gdprConfig = nullId.presets.gdpr();

// HIPAA Configuration
const hipaaConfig = nullId.presets.hipaa();

// CCPA Configuration
const ccpaConfig = nullId.presets.ccpa();`}
            </Code>
          </VStack>
        </TabPanel>
      </TabPanels>
    </Tabs>
  </VStack>
);

const APIReferenceSection = () => (
  <VStack align="stretch" spacing={4}>
    <Heading size="lg">API Reference</Heading>
    <Text>
      Explore the complete API reference for Null-ID's core functionality and advanced features.
    </Text>
  </VStack>
);

const SyntheticDataSection = () => (
  <VStack align="stretch" spacing={4}>
    <Heading size="lg">Synthetic Data Generation</Heading>
    <Text>
      Learn how to generate realistic but completely anonymous synthetic data for testing and development.
    </Text>
  </VStack>
);

export const Documentation = () => {
  const [activeSection, setActiveSection] = useState('quickstart');
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  const renderContent = () => {
    switch (activeSection) {
      case 'quickstart':
        return <QuickStartSection />;
      case 'configuration':
        return <ConfigurationSection />;
      case 'api':
        return <APIReferenceSection />;
      case 'synthetic':
        return <SyntheticDataSection />;
      default:
        return <QuickStartSection />;
    }
  };

  return (
    <Box bg={bgColor}>
      <Container maxW="container.xl" py={8}>
        <Heading size="2xl" mb={8}>Documentation</Heading>
        
        <Flex gap={8}>
          <DocSidebar activeSection={activeSection} setActiveSection={setActiveSection} />
          
          <Box flex={1} borderLeft="1px" borderColor={borderColor} pl={8}>
            {renderContent()}
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};
