import React from 'react';
import {
  Button,
  Stack,
  Text,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  List,
  ListItem,
  useTheme,
  useToast,
  Heading,
  Box,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { LemonSqueezyPlan } from '../../types/lemon-squeezy';
import { useSubscription } from '../../contexts/SubscriptionContext';
import { lemonSqueezyService } from '../../api/services/lemon-squeezy-service';
import { useAuth } from '../../contexts/AuthContext';

interface PlanCardProps {
  plan: LemonSqueezyPlan;
  isCurrentPlan?: boolean;
  onSelect: (plan: LemonSqueezyPlan) => void;
}

const PlanCard: React.FC<PlanCardProps> = ({ plan, isCurrentPlan, onSelect }) => {
  const theme = useTheme();

  const PropertyItem: React.FC<{ label: string; value: string }> = ({ label, value }) => (
    <Box>
      <Text fontSize="sm" color="gray.500">{label}</Text>
      <Text fontWeight="medium">{value}</Text>
    </Box>
  );

  return (
    <Card
      maxW="sm"
      variant={isCurrentPlan ? 'filled' : 'outline'}
      borderColor={isCurrentPlan ? 'blue.500' : undefined}
    >
      <CardHeader>
        <Stack spacing={4}>
          <Heading size="md">{plan.name}</Heading>
          <Text color="gray.500">
            {plan.description || 'No description available'}
          </Text>
          <PropertyItem
            label="Price"
            value={`$${plan.price.amount}/${plan.price.interval}`}
          />
          {plan.limits && (
            <Stack spacing={3}>
              <PropertyItem 
                label="Records" 
                value={`${plan.limits.records.toLocaleString()} per month`} 
              />
              <PropertyItem 
                label="API Requests" 
                value={`${plan.limits.requests.toLocaleString()} per month`} 
              />
              <PropertyItem
                label="Storage"
                value={`${(plan.limits.storage / 1024 / 1024 / 1024).toFixed(0)} GB`}
              />
            </Stack>
          )}
        </Stack>
      </CardHeader>
      <CardBody>
        <List spacing={3}>
          {plan.features.map((feature, index) => (
            <ListItem key={index} display="flex" alignItems="center">
              <CheckIcon color={theme.colors.green[500]} mr={2} />
              {feature}
            </ListItem>
          ))}
        </List>
      </CardBody>
      <CardFooter>
        <Button
          colorScheme="blue"
          width="full"
          onClick={() => onSelect(plan)}
          isDisabled={isCurrentPlan}
        >
          {isCurrentPlan ? 'Current Plan' : 'Select Plan'}
        </Button>
      </CardFooter>
    </Card>
  );
};

export const SubscriptionPlans: React.FC = () => {
  const { subscription } = useSubscription();
  const { user, isAuthenticated, checkUser } = useAuth();
  const [plans, setPlans] = React.useState<LemonSqueezyPlan[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const toast = useToast();

  React.useEffect(() => {
    // Check auth state when component mounts
    checkUser();
  }, [checkUser]);

  React.useEffect(() => {
    const fetchPlans = async () => {
      setIsLoading(true);
      try {
        const fetchedPlans = await lemonSqueezyService.getVariants();
        setPlans(fetchedPlans);
      } catch (error) {
        console.error('Error fetching plans:', error);
        toast({
          title: 'Error fetching plans',
          description: 'Please try again later',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchPlans();
  }, [toast]);

  const handlePlanSelect = async (plan: LemonSqueezyPlan) => {
    try {
      if (!isAuthenticated || !user) {
        toast({
          title: 'Authentication required',
          description: 'Please sign in to subscribe to a plan',
          status: 'warning',
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      if (plan.id === 'FREE') {
        toast({
          title: 'Free Plan',
          description: 'You are now on the Free plan. Enjoy!',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      const checkoutUrl = await lemonSqueezyService.createCheckout(plan.id, user.userId);
      window.location.href = checkoutUrl;
    } catch (error) {
      console.error('Error starting subscription:', error);
      toast({
        title: 'Error starting subscription',
        description: 'Please try again later',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  if (isLoading) {
    return (
      <Stack spacing={8} direction={{ base: 'column', lg: 'row' }} justify="center" align="center" py={8}>
        <Text>Loading plans...</Text>
      </Stack>
    );
  }

  return (
    <Stack spacing={8} direction={{ base: 'column', lg: 'row' }} justify="center" align="center" py={8}>
      {plans.map((plan) => (
        <PlanCard
          key={plan.id}
          plan={plan}
          isCurrentPlan={subscription?.plan.id === plan.id}
          onSelect={handlePlanSelect}
        />
      ))}
    </Stack>
  );
};
