import React from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  Stack,
  Button,
  useColorModeValue,
} from '@chakra-ui/react';
import { SubscriptionPlans } from '../components/subscription/SubscriptionPlans';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

export const PricingPage: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const bgColor = useColorModeValue('gray.50', 'gray.900');

  return (
    <Box as="main" bg={bgColor} py={20}>
      <Container maxW="container.xl">
        <Stack spacing={8} align="center" textAlign="center">
          <Stack spacing={3}>
            <Heading size="2xl">Simple, Transparent Pricing</Heading>
            <Text fontSize="xl" color="gray.500">
              Choose the plan that best fits your needs
            </Text>
          </Stack>

          {!isAuthenticated && (
            <Text color="gray.600">
              Already have an account?{' '}
              <Button
                variant="link"
                colorScheme="blue"
                onClick={() => navigate('/login')}
              >
                Sign in
              </Button>
            </Text>
          )}

          <Box w="full" py={8}>
            <SubscriptionPlans />
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};
