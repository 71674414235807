import { useState, useEffect, useCallback } from 'react';
import { getCurrentUser, signOut as amplifySignOut, AuthUser, signIn, fetchUserAttributes } from '@aws-amplify/auth';

export function useAuth() {
  const [user, setUser] = useState<AuthUser & { username?: string } | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const checkUser = useCallback(async () => {
    try {
      const currentUser = await getCurrentUser();
      const userAttributes = await fetchUserAttributes();
      
      // Combine user data with attributes
      const enrichedUser = {
        ...currentUser,
        username: userAttributes.preferred_username || userAttributes.email || currentUser.username
      };
      
      setUser(enrichedUser);
      setIsAuthenticated(true);
    } catch (error) {
      // This error is expected for unauthenticated users
      if (error instanceof Error && error.name !== 'UserUnAuthenticatedException') {
        console.error('Auth check error:', error);
      }
      setUser(null);
      setIsAuthenticated(false);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    checkUser();
  }, [checkUser]);

  const login = async (username: string, password: string) => {
    try {
      const signInResult = await signIn({
        username,
        password,
        options: {
          authFlowType: "USER_PASSWORD_AUTH"
        }
      });
      
      if (signInResult.isSignedIn) {
        // After successful sign in, check user again to update state
        await checkUser();
        return signInResult;
      }
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  };

  const signOut = useCallback(async () => {
    try {
      await amplifySignOut();
      setUser(null);
      setIsAuthenticated(false);
      window.location.replace('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  }, []);

  return {
    user,
    isAuthenticated,
    isLoading,
    login,
    signOut,
    checkUser // Export checkUser to allow manual refresh of auth state
  };
}
