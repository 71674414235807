// Initialize Lemon Squeezy with store ID and API key
const LEMON_SQUEEZY_API_URL = 'https://api.lemonsqueezy.com/v1';
const API_KEY = import.meta.env.VITE_LEMON_SQUEEZY_API_KEY;
const STORE_ID = import.meta.env.VITE_LEMON_SQUEEZY_STORE_ID;

if (!API_KEY) {
  throw new Error('Lemon Squeezy API key missing. Please set VITE_LEMON_SQUEEZY_API_KEY environment variable.');
}

import { LemonSqueezyPlan, LemonSqueezySubscription } from '../../types/lemon-squeezy';

export const lemonSqueezyService = {
  /**
   * Get all variants (plans) for the store
   */
  async getVariants(): Promise<LemonSqueezyPlan[]> {
    try {
      // Get all variants first
      const response = await fetch(`${LEMON_SQUEEZY_API_URL}/variants`, {
        headers: {
          'Accept': 'application/vnd.api+json',
          'Authorization': `Bearer ${API_KEY}`
        }
      });

      if (!response.ok) {
        const error = await response.json();
        console.error('Variants error response:', JSON.stringify(error, null, 2));
        throw new Error(error.errors?.[0]?.detail || `HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      
      // Filter variants for our store
      const storeVariants = data.data.filter((variant: any) => 
        variant.attributes.store_id === parseInt(STORE_ID)
      );

      return storeVariants.map((variant: any) => ({
        id: variant.id,
        name: variant.attributes.name,
        description: variant.attributes.description,
        price: {
          amount: variant.attributes.price / 100, // Convert from cents to dollars
          interval: 'month'
        },
        features: [], // You'll need to define these features somewhere
        limits: {
          records: 1000000,
          requests: 100000,
          storage: 5 * 1024 * 1024 * 1024 // 5GB
        }
      }));
    } catch (error) {
      console.error('Error getting variants:', error);
      throw error;
    }
  },

  /**
   * Get a specific variant by ID
   */
  async getVariant(variantId: string): Promise<LemonSqueezyPlan> {
    try {
      const response = await fetch(`${LEMON_SQUEEZY_API_URL}/variants/${variantId}`, {
        headers: {
          'Accept': 'application/vnd.api+json',
          'Authorization': `Bearer ${API_KEY}`
        }
      });

      if (!response.ok) {
        const error = await response.json();
        console.error('Variant error response:', JSON.stringify(error, null, 2));
        throw new Error(error.errors?.[0]?.detail || `HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const attributes = data.data.attributes;

      return {
        id: data.data.id,
        name: attributes.name,
        description: attributes.description,
        price: {
          amount: attributes.price / 100,
          interval: 'month'
        },
        features: [], // You'll need to define these features somewhere
        limits: {
          records: 1000000,
          requests: 100000,
          storage: 5 * 1024 * 1024 * 1024 // 5GB
        }
      };
    } catch (error) {
      console.error('Error getting variant:', error);
      throw error;
    }
  },

  /**
   * Get subscription for a user
   */
  async getSubscription(
    customerId: string,
  ): Promise<LemonSqueezySubscription | null> {
    try {
      const response = await fetch(`${LEMON_SQUEEZY_API_URL}/v1/subscriptions?filter[customer_id]=${customerId}&include=variant`, {
        headers: {
          'Accept': 'application/vnd.api+json',
          'Authorization': `Bearer ${API_KEY}`,
        },
      });

      const subscriptions = await response.json();
      if (subscriptions.data.length === 0) {
        return null;
      }

      const subscription = subscriptions.data[0];
      const variant = subscriptions.included.find(
        (item: any) => item.type === 'variants',
      );

      return {
        status: subscription.attributes.status,
        planId: variant.id,
        customerId: subscription.attributes.customer_id,
        startDate: subscription.attributes.created_at,
        endDate: subscription.attributes.ends_at,
        cancelAtPeriodEnd: subscription.attributes.cancelled,
        urls: {
          updatePaymentMethod: subscription.attributes.urls.update_payment_method,
          customerPortal: subscription.attributes.urls.customer_portal,
          customerPortalUpdateSubscription:
            subscription.attributes.urls.customer_portal_update_subscription,
        },
        plan: {
          id: variant.id,
          name: variant.attributes.name,
          description: variant.attributes.description,
          price: {
            amount: variant.attributes.price,
            interval: variant.attributes.interval as 'month' | 'year',
          },
          limits: {
            records: variant.attributes.meta.records_limit,
            requests: variant.attributes.meta.requests_limit,
            storage: variant.attributes.meta.storage_limit,
          },
          features: variant.attributes.meta.features || [],
        },
        usage: subscription.attributes.meta
          ? {
              records: {
                used: subscription.attributes.meta.records_used,
                limit: subscription.attributes.meta.records_limit,
                resetDate: subscription.attributes.renews_at,
              },
              requests: {
                used: subscription.attributes.meta.requests_used,
                limit: subscription.attributes.meta.requests_limit,
                resetDate: subscription.attributes.renews_at,
              },
              storage: {
                used: subscription.attributes.meta.storage_used,
                limit: subscription.attributes.meta.storage_limit,
                resetDate: subscription.attributes.renews_at,
              },
            }
          : undefined,
      };
    } catch (error) {
      console.error('Error fetching subscription:', error);
      return null;
    }
  },

  /**
   * Cancel a subscription
   */
  async cancelSubscription(subscriptionId: string): Promise<void> {
    try {
      const response = await fetch(`${LEMON_SQUEEZY_API_URL}/subscriptions/${subscriptionId}`, {
        method: 'DELETE',
        headers: {
          'Accept': 'application/vnd.api+json',
          'Authorization': `Bearer ${API_KEY}`
        }
      });

      if (!response.ok) {
        const error = await response.json();
        console.error('Cancel subscription error response:', JSON.stringify(error, null, 2));
        throw new Error(error.errors?.[0]?.detail || `HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error cancelling subscription:', error);
      throw error;
    }
  },

  /**
   * Get URL to update payment method
   */
  async getUpdatePaymentMethodUrl(subscriptionId: string): Promise<string> {
    try {
      const subscription = await this.getSubscription(subscriptionId);
      if (!subscription) {
        throw new Error('Subscription not found');
      }
      return subscription.urls?.updatePaymentMethod || '';
    } catch (error) {
      console.error('Error getting update payment URL:', error);
      throw error;
    }
  },

  /**
   * Get billing history for a user
   */
  async getBillingHistory(userId: string): Promise<{
    id: string;
    date: string;
    description: string;
    amount: string;
    status: string;
    invoice_url: string;
  }[]> {
    try {
      // First get the customer ID from subscriptions
      const subscription = await this.getSubscription(userId);
      if (!subscription) {
        return [];
      }

      // Get orders for the customer
      const response = await fetch(`${LEMON_SQUEEZY_API_URL}/orders?filter[customer_id]=${subscription.customerId}`, {
        headers: {
          'Accept': 'application/vnd.api+json',
          'Authorization': `Bearer ${API_KEY}`
        }
      });

      if (!response.ok) {
        const error = await response.json();
        console.error('Orders error response:', JSON.stringify(error, null, 2));
        throw new Error(error.errors?.[0]?.detail || `HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data.data.map((order: any) => ({
        id: order.id,
        date: order.attributes.created_at,
        description: order.attributes.first_order_item.product_name,
        amount: order.attributes.total_formatted,
        status: order.attributes.status,
        invoice_url: order.attributes.urls.invoice
      }));
    } catch (error) {
      console.error('Error fetching billing history:', error);
      throw error;
    }
  },

  /**
   * Create a checkout session for a plan
   */
  async createCheckout(variantId: string, customerId: string): Promise<string> {
    try {
      const response = await fetch(`${LEMON_SQUEEZY_API_URL}/v1/checkouts`, {
        method: 'POST',
        headers: {
          'Accept': 'application/vnd.api+json',
          'Content-Type': 'application/vnd.api+json',
          'Authorization': `Bearer ${API_KEY}`,
        },
        body: JSON.stringify({
          data: {
            type: 'checkouts',
            attributes: {
              checkout_data: {
                variant_id: variantId,
                custom: {
                  customer_id: customerId,
                },
              },
            },
          },
        }),
      });

      const data = await response.json();
      return data.data.attributes.url;
    } catch (error) {
      console.error('Error creating checkout:', error);
      throw error;
    }
  },
};