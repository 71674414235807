import React from 'react';
import {
  Box,
  Flex,
  Text,
  Button,
  Stack,
  Collapse,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
  Container,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
} from '@chakra-ui/react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { FiMenu, FiX, FiChevronDown, FiUser, FiLogOut } from 'react-icons/fi';

interface NavLink {
  label: string;
  path: string;
  authRequired?: boolean;
}

interface NavButtonProps {
  to: string;
  children: React.ReactNode;
  variant?: string;
  size?: string;
  colorScheme?: string;
  onClick?: () => void;
}

const NAV_LINKS: NavLink[] = [
  { label: 'Dashboard', path: '/dashboard', authRequired: true },
  { label: 'Pricing', path: '/pricing' },
  { label: 'Documentation', path: '/docs' },
];

const NavButton: React.FC<NavButtonProps> = ({
  to,
  children,
  variant = 'ghost',
  size = 'sm',
  colorScheme,
  onClick,
}) => (
  <Button
    as={RouterLink}
    to={to}
    variant={variant}
    colorScheme={colorScheme}
    size={size}
    onClick={onClick}
  >
    {children}
  </Button>
);

const Navbar: React.FC = () => {
  const { isOpen, onToggle } = useDisclosure();
  const { isAuthenticated, user, signOut } = useAuth();
  const location = useLocation();

  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  const isActive = (path: string) => location.pathname === path;

  const getNavLinks = () => NAV_LINKS.filter(
    link => !link.authRequired || (link.authRequired && isAuthenticated)
  );

  return (
    <Box position="sticky" top={0} zIndex={1000}>
      <Box
        bg={bgColor}
        borderBottom={1}
        borderStyle="solid"
        borderColor={borderColor}
        boxShadow="sm"
      >
        <Container maxW="container.xl">
          <Flex
            minH="60px"
            py={{ base: 2 }}
            px={{ base: 4 }}
            align="center"
            justify="space-between"
          >
            {/* Mobile menu button */}
            <Flex
              flex={{ base: 1, md: 'auto' }}
              ml={{ base: -2 }}
              display={{ base: 'flex', md: 'none' }}
            >
              <IconButton
                onClick={onToggle}
                icon={isOpen ? <FiX /> : <FiMenu />}
                variant="ghost"
                aria-label="Toggle Navigation"
                size="lg"
              />
            </Flex>

            {/* Logo */}
            <Text
              as={RouterLink}
              to="/"
              textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
              fontFamily="heading"
              fontSize="xl"
              fontWeight="bold"
              bgGradient="linear(to-r, blue.400, blue.600)"
              bgClip="text"
              _hover={{
                bgGradient: 'linear(to-r, blue.500, blue.700)',
                textDecoration: 'none',
              }}
            >
              Null ID
            </Text>

            {/* Desktop Navigation */}
            <Stack
              direction="row"
              spacing={4}
              display={{ base: 'none', md: 'flex' }}
              flex={1}
              justify="center"
            >
              {getNavLinks().map(link => (
                <NavButton
                  key={link.path}
                  to={link.path}
                  variant={isActive(link.path) ? 'solid' : 'ghost'}
                  colorScheme={isActive(link.path) ? 'blue' : undefined}
                >
                  {link.label}
                </NavButton>
              ))}
            </Stack>

            {/* Right side - Auth buttons or User menu */}
            <Stack
              flex={{ base: 1, md: 0 }}
              justify="flex-end"
              direction="row"
              spacing={6}
              align="center"
            >
              {isAuthenticated ? (
                <Menu>
                  <MenuButton
                    as={Button}
                    variant="ghost"
                    rightIcon={<FiChevronDown />}
                  >
                    <Avatar size="sm" name={user?.username} mr={2} />
                    {user?.username}
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      as={RouterLink}
                      to="/subscription"
                      icon={<FiUser />}
                    >
                      Subscription & Usage
                    </MenuItem>
                    <MenuItem
                      onClick={signOut}
                      icon={<FiLogOut />}
                    >
                      Sign Out
                    </MenuItem>
                  </MenuList>
                </Menu>
              ) : (
                <>
                  <NavButton to="/login" variant="ghost">
                    Sign In
                  </NavButton>
                  <NavButton to="/register" variant="solid" colorScheme="blue">
                    Sign Up
                  </NavButton>
                </>
              )}
            </Stack>
          </Flex>

          {/* Mobile Navigation */}
          <Collapse in={isOpen} animateOpacity>
            <Stack
              p={4}
              display={{ md: 'none' }}
              spacing={4}
              divider={<Box borderColor={borderColor} borderBottomWidth={1} />}
            >
              {getNavLinks().map(link => (
                <NavButton
                  key={link.path}
                  to={link.path}
                  variant={isActive(link.path) ? 'solid' : 'ghost'}
                  colorScheme={isActive(link.path) ? 'blue' : undefined}
                >
                  {link.label}
                </NavButton>
              ))}
              {!isAuthenticated && (
                <>
                  <NavButton to="/login" variant="ghost">
                    Sign In
                  </NavButton>
                  <NavButton to="/register" variant="solid" colorScheme="blue">
                    Sign Up
                  </NavButton>
                </>
              )}
            </Stack>
          </Collapse>
        </Container>
      </Box>
    </Box>
  );
};

export default Navbar;
