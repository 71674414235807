import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useColorModeValue,
} from '@chakra-ui/react';

export default function FAQ() {
  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.600', 'gray.300');

  const faqs = [
    {
      question: "What is Null-ID?",
      answer: "Null-ID is a secure data anonymization service designed for modern applications. We help businesses protect sensitive data while maintaining data utility for analytics and testing."
    },
    {
      question: "How does data anonymization work?",
      answer: "Our service uses advanced algorithms to replace sensitive information with realistic but fake data while maintaining the statistical properties and relationships within the dataset."
    },
    {
      question: "Is my data secure?",
      answer: "Yes, we take security seriously. All data is encrypted in transit and at rest, and we follow industry best practices for data protection. We never store your original data after processing."
    },
    {
      question: "What types of data can be anonymized?",
      answer: "We support various data types including personal information (names, addresses, SSNs), financial data, healthcare records, and custom data types based on your needs."
    },
    {
      question: "How do I get started?",
      answer: "Sign up for an account, choose your subscription plan, and you can start using our API or web interface immediately. We provide comprehensive documentation and support to help you get started."
    },
    {
      question: "What are the pricing plans?",
      answer: "We offer flexible pricing plans based on your usage needs, from free tier for small projects to enterprise plans for large-scale operations. Visit our pricing page for detailed information."
    },
    {
      question: "Do you offer custom solutions?",
      answer: "Yes, we offer custom enterprise solutions tailored to your specific needs. Contact our sales team to discuss your requirements."
    }
  ];

  return (
    <Box bg={useColorModeValue('gray.50', 'gray.900')} minH="100vh" py={10}>
      <Container maxW="container.md">
        <VStack spacing={6} align="stretch" bg={bgColor} p={8} borderRadius="lg" boxShadow="sm">
          <Heading as="h1" size="xl">Frequently Asked Questions</Heading>
          
          <Text color={textColor} mb={4}>
            Find answers to commonly asked questions about Null-ID's services.
          </Text>

          <Accordion allowMultiple>
            {faqs.map((faq, index) => (
              <AccordionItem key={index}>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <Text fontWeight="semibold">{faq.question}</Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4} color={textColor}>
                  {faq.answer}
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>

          <Text color={textColor} mt={4}>
            Still have questions? Contact us at privacy@null-id.com
          </Text>
        </VStack>
      </Container>
    </Box>
  );
}
