import { useState, useEffect } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Text,
  Link,
  useToast,
  Container,
  Center,
  Spinner,
  Card,
  CardBody,
  useColorModeValue,
  Icon,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { FiUser, FiLock } from 'react-icons/fi';
import { useAuth } from '../hooks/useAuth';
import { Logo } from '../components/Layout/Logo';

export default function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const { login, isAuthenticated, isLoading: isAuthChecking } = useAuth();
  const cardBg = useColorModeValue('white', 'gray.800');
  const inputBg = useColorModeValue('white', 'gray.700');

  useEffect(() => {
    if (isAuthenticated && !isAuthChecking) {
      navigate('/', { replace: true });
    }
  }, [isAuthenticated, isAuthChecking, navigate]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await login(username, password);
      navigate('/', { replace: true });
    } catch (error) {
      console.error('Sign in error:', error);
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to sign in',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (isAuthChecking) {
    return (
      <Center height="100vh">
        <Spinner size="xl" color="brand.500" />
      </Center>
    );
  }

  return (
    <Box
      minH="100vh"
      bg="gray.50"
      py={12}
      px={4}
      bgGradient="linear(to-r, gray.50, brand.50)"
    >
      <Container maxW="md">
        <VStack spacing={8}>
          <Box textAlign="center">
            <Logo />
            <Text mt={4} color="gray.600" fontSize="lg">
              Sign in to your account
            </Text>
          </Box>

          <Card bg={cardBg} shadow="lg" w="full">
            <CardBody>
              <form onSubmit={handleSubmit}>
                <VStack spacing={6}>
                  <FormControl isRequired>
                    <FormLabel>Username</FormLabel>
                    <InputGroup>
                      <InputLeftElement>
                        <Icon as={FiUser} color="gray.400" />
                      </InputLeftElement>
                      <Input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Enter your username"
                        bg={inputBg}
                      />
                    </InputGroup>
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel>Password</FormLabel>
                    <InputGroup>
                      <InputLeftElement>
                        <Icon as={FiLock} color="gray.400" />
                      </InputLeftElement>
                      <Input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter your password"
                        bg={inputBg}
                      />
                    </InputGroup>
                  </FormControl>

                  <Button
                    type="submit"
                    size="lg"
                    width="full"
                    isLoading={isLoading}
                    loadingText="Signing in..."
                  >
                    Sign In
                  </Button>
                </VStack>
              </form>
            </CardBody>
          </Card>

          <Text color="gray.600">
            Don't have an account?{' '}
            <Link
              as={RouterLink}
              to="/register"
              color="brand.500"
              fontWeight="semibold"
              _hover={{ color: 'brand.600' }}
            >
              Register here
            </Link>
          </Text>
        </VStack>
      </Container>
    </Box>
  );
}
