import { extendTheme } from '@chakra-ui/react';
import type { ChakraTheme } from '@chakra-ui/react';

const config: ChakraTheme['config'] = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const colors = {
  brand: {
    50: '#e6f6ff',
    100: '#b3e0ff',
    200: '#80cbff',
    300: '#4db5ff',
    400: '#1a9fff',
    500: '#0088e6',
    600: '#006bb3',
    700: '#004d80',
    800: '#00304d',
    900: '#00121a',
  },
};

const components: ChakraTheme['components'] = {
  Button: {
    baseStyle: {
      _loading: {
        opacity: 0.8,
      },
      _disabled: {
        opacity: 0.6,
      },
    },
    defaultProps: {
      colorScheme: 'brand',
    },
  },
  Link: {
    baseStyle: {
      _hover: {
        textDecoration: 'none',
        color: 'brand.600',
      },
    },
  },
};

export const theme = extendTheme({
  config,
  colors,
  components,
  styles: {
    global: {
      body: {
        bg: 'gray.50',
      },
    },
  },
});
