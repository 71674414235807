import React, { useEffect, useState } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Link,
  Text,
  Spinner,
  Badge,
} from '@chakra-ui/react';
import { lemonSqueezyService } from '../../api/services/lemon-squeezy-service';
import { useAuth } from '../../contexts/AuthContext';

interface BillingHistoryOrder {
  id: string;
  date: string;
  description: string;
  amount: string;
  status: string;
  invoice_url: string;
}

export const BillingHistory: React.FC = () => {
  const [orders, setOrders] = useState<BillingHistoryOrder[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { user } = useAuth();

  useEffect(() => {
    const fetchBillingHistory = async () => {
      try {
        if (!user?.userId) return;
        const history = await lemonSqueezyService.getBillingHistory(user.userId);
        setOrders(history);
      } catch (err) {
        console.error('Error fetching billing history:', err);
        setError('Failed to load billing history');
      } finally {
        setIsLoading(false);
      }
    };

    fetchBillingHistory();
  }, [user]);

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <Text color="red.500">{error}</Text>;
  }

  if (!orders.length) {
    return <Text>No billing history available.</Text>;
  }

  return (
    <Box overflowX="auto">
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Date</Th>
            <Th>Description</Th>
            <Th>Amount</Th>
            <Th>Status</Th>
            <Th>Invoice</Th>
          </Tr>
        </Thead>
        <Tbody>
          {orders.map((order) => (
            <Tr key={order.id}>
              <Td>{new Date(order.date).toLocaleDateString()}</Td>
              <Td>{order.description}</Td>
              <Td>{order.amount}</Td>
              <Td>
                <Badge
                  colorScheme={
                    order.status === 'paid'
                      ? 'green'
                      : order.status === 'pending'
                      ? 'yellow'
                      : 'red'
                  }
                >
                  {order.status}
                </Badge>
              </Td>
              <Td>
                {order.invoice_url && (
                  <Link href={order.invoice_url} isExternal color="blue.500">
                    View Invoice
                  </Link>
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};
