import React, { useCallback } from 'react';
import {
  Box,
  Stack,
  Heading,
  Text,
  Badge,
  Card,
  Button,
  Center,
  Spinner,
  Alert,
  AlertIcon,
  HStack,
  Progress,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { BillingHistory } from '../components/subscription/BillingHistory';
import { useSubscription } from '../hooks/useSubscription';

export const SubscriptionPage: React.FC = () => {
  const { subscription, isLoading, error } = useSubscription();

  const handleUpdatePaymentMethod = useCallback(() => {
    if (subscription?.urls?.updatePaymentMethod) {
      window.location.href = subscription.urls.updatePaymentMethod;
    }
  }, [subscription]);

  const handleManageSubscription = useCallback(() => {
    if (subscription?.urls?.customerPortalUpdateSubscription) {
      window.location.href = subscription.urls.customerPortalUpdateSubscription;
    }
  }, [subscription]);

  const handleViewBillingPortal = useCallback(() => {
    if (subscription?.urls?.customerPortal) {
      window.location.href = subscription.urls.customerPortal;
    }
  }, [subscription]);

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'active':
        return 'green';
      case 'on_trial':
        return 'blue';
      default:
        return 'red';
    }
  };

  const formatBytes = (bytes: number) => {
    if (bytes === 0) return '0 B';
    const k = 1024;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${(bytes / Math.pow(k, i)).toFixed(2)} ${sizes[i]}`;
  };

  return (
    <Box>
      <Heading size="lg" mb={6}>
        Subscription
      </Heading>

      {isLoading ? (
        <Center>
          <Spinner />
        </Center>
      ) : error ? (
        <Alert status="error">
          <AlertIcon />
          {error instanceof Error ? error.message : 'An error occurred'}
        </Alert>
      ) : !subscription ? (
        <Alert status="info">
          <AlertIcon />
          No active subscription found
        </Alert>
      ) : (
        <>
          <Stack spacing={4}>
            <Card>
              <Box p={5}>
                <Heading size="md" mb={4}>Current Plan</Heading>
                <Stack spacing={4}>
                  <HStack justify="space-between">
                    <Text fontWeight="bold">Plan</Text>
                    <Text>{subscription.plan.name}</Text>
                  </HStack>
                  <HStack justify="space-between">
                    <Text fontWeight="bold">Status</Text>
                    <Badge colorScheme={getStatusColor(subscription.status)}>
                      {subscription.status}
                    </Badge>
                  </HStack>
                  <HStack justify="space-between">
                    <Text fontWeight="bold">Price</Text>
                    <Text>
                      ${subscription.plan.price.amount / 100}/
                      {subscription.plan.price.interval}
                    </Text>
                  </HStack>
                  <HStack justify="space-between">
                    <Text fontWeight="bold">Next Payment</Text>
                    <Text>
                      {subscription.endDate
                        ? format(new Date(subscription.endDate), 'PP')
                        : 'N/A'}
                    </Text>
                  </HStack>
                </Stack>
              </Box>
            </Card>

            {subscription.usage && (
              <Card>
                <Box p={5}>
                  <Heading size="md" mb={4}>Usage</Heading>
                  <Stack spacing={4}>
                    <Box>
                      <Text fontWeight="bold" mb={2}>
                        Records
                      </Text>
                      <Progress
                        value={
                          (subscription.usage.records.used /
                            subscription.usage.records.limit) *
                          100
                        }
                        max={100}
                        colorScheme="blue"
                      />
                      <Text mt={1} fontSize="sm" color="gray.600">
                        {subscription.usage.records.used.toLocaleString()} /{' '}
                        {subscription.usage.records.limit.toLocaleString()}
                      </Text>
                    </Box>

                    <Box>
                      <Text fontWeight="bold" mb={2}>
                        API Requests
                      </Text>
                      <Progress
                        value={
                          (subscription.usage.requests.used /
                            subscription.usage.requests.limit) *
                          100
                        }
                        max={100}
                        colorScheme="blue"
                      />
                      <Text mt={1} fontSize="sm" color="gray.600">
                        {subscription.usage.requests.used.toLocaleString()} /{' '}
                        {subscription.usage.requests.limit.toLocaleString()}
                      </Text>
                    </Box>

                    <Box>
                      <Text fontWeight="bold" mb={2}>
                        Storage
                      </Text>
                      <Progress
                        value={
                          (subscription.usage.storage.used /
                            subscription.usage.storage.limit) *
                          100
                        }
                        max={100}
                        colorScheme="blue"
                      />
                      <Text mt={1} fontSize="sm" color="gray.600">
                        {formatBytes(subscription.usage.storage.used)} /{' '}
                        {formatBytes(subscription.usage.storage.limit)}
                      </Text>
                    </Box>
                  </Stack>
                </Box>
              </Card>
            )}

            <Card>
              <Box p={5}>
                <Heading size="md" mb={4}>Manage Subscription</Heading>
                <Stack spacing={4}>
                  <Button
                    colorScheme="blue"
                    onClick={handleUpdatePaymentMethod}
                    isDisabled={!subscription.urls?.updatePaymentMethod}
                  >
                    Update Payment Method
                  </Button>
                  <Button
                    onClick={handleManageSubscription}
                    isDisabled={!subscription.urls?.customerPortalUpdateSubscription}
                  >
                    Change Plan
                  </Button>
                  <Button
                    onClick={handleViewBillingPortal}
                    isDisabled={!subscription.urls?.customerPortal}
                  >
                    View Billing Portal
                  </Button>
                </Stack>
              </Box>
            </Card>

            <BillingHistory />
          </Stack>
        </>
      )}
    </Box>
  );
};
