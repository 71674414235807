import { Box, Container } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import Navbar from '../Navbar';
import Footer from '../Footer';

export default function MainLayout() {
  return (
    <Box minH="100vh" bg="gray.50" display="flex" flexDirection="column">
      <Navbar />
      <Container maxW="container.xl" as="main" py={8} flex="1">
        <Outlet />
      </Container>
      <Footer />
    </Box>
  );
}
