import { useEffect, useState } from 'react';
import { LemonSqueezySubscription } from '../types/lemon-squeezy';
import { useAuth } from '../contexts/AuthContext';
import { lemonSqueezyService } from '../api/services/lemon-squeezy-service';

export function useSubscription() {
  const { user } = useAuth();
  const [subscription, setSubscription] = useState<LemonSqueezySubscription | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    async function fetchSubscription() {
      if (!user?.userId) {
        setIsLoading(false);
        return;
      }

      try {
        const sub = await lemonSqueezyService.getSubscription(user.userId);
        setSubscription(sub);
        setError(null);
      } catch (err) {
        setError(err instanceof Error ? err : new Error('Failed to fetch subscription'));
      } finally {
        setIsLoading(false);
      }
    }

    fetchSubscription();
  }, [user?.userId]);

  return { subscription, isLoading, error };
}
