import { VStack, Button } from '@chakra-ui/react';

interface DocSidebarProps {
  activeSection: string;
  setActiveSection: (section: string) => void;
}

export const DocSidebar = ({ activeSection, setActiveSection }: DocSidebarProps) => {
  const sections = [
    { id: 'quickstart', title: 'Quick Start' },
    { id: 'installation', title: 'Installation' },
    { id: 'configuration', title: 'Configuration' },
    { id: 'api', title: 'API Reference' },
    { id: 'synthetic', title: 'Synthetic Data' },
    { id: 'examples', title: 'Examples' },
    { id: 'privacy', title: 'Privacy Controls' },
    { id: 'deployment', title: 'Deployment' },
    { id: 'faq', title: 'FAQ' },
  ];

  return (
    <VStack align="stretch" spacing={2} position="sticky" top="4" width="250px">
      {sections.map((section) => (
        <Button
          key={section.id}
          variant={activeSection === section.id ? 'solid' : 'ghost'}
          colorScheme={activeSection === section.id ? 'blue' : 'gray'}
          justifyContent="flex-start"
          onClick={() => setActiveSection(section.id)}
          size="sm"
        >
          {section.title}
        </Button>
      ))}
    </VStack>
  );
};
