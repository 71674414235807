import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';

export default function PrivacyPolicy() {
  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.600', 'gray.300');

  return (
    <Box bg={useColorModeValue('gray.50', 'gray.900')} minH="100vh" py={10}>
      <Container maxW="container.md">
        <VStack spacing={6} align="stretch" bg={bgColor} p={8} borderRadius="lg" boxShadow="sm">
          <Heading as="h1" size="xl">Privacy Policy</Heading>
          
          <Text color={textColor}>Last updated: {new Date().toLocaleDateString()}</Text>

          <VStack spacing={4} align="stretch">
            <Heading as="h2" size="lg">1. Introduction</Heading>
            <Text color={textColor}>
              At Null-ID, we take your privacy seriously. This Privacy Policy explains how we collect,
              use, disclose, and safeguard your information when you use our service.
            </Text>

            <Heading as="h2" size="lg">2. Information We Collect</Heading>
            <Text color={textColor}>
              We collect information that you provide directly to us, including:
              - Account information (email, name)
              - Usage data
              - Payment information
            </Text>

            <Heading as="h2" size="lg">3. How We Use Your Information</Heading>
            <Text color={textColor}>
              We use the information we collect to:
              - Provide and maintain our service
              - Process your transactions
              - Send you technical notices and updates
              - Respond to your comments and questions
            </Text>

            <Heading as="h2" size="lg">4. Data Security</Heading>
            <Text color={textColor}>
              We implement appropriate technical and organizational security measures to protect your
              personal information. However, no method of transmission over the Internet is 100% secure.
            </Text>

            <Heading as="h2" size="lg">5. Contact Us</Heading>
            <Text color={textColor}>
              If you have any questions about this Privacy Policy, please contact us at:
              privacy@null-id.com
            </Text>
          </VStack>
        </VStack>
      </Container>
    </Box>
  );
}
