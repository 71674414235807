import { Flex, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

export function Logo() {
  return (
    <Link to="/">
      <Flex align="center">
        <Text
          fontSize="xl"
          fontWeight="bold"
          bgGradient="linear(to-r, brand.400, brand.600)"
          bgClip="text"
          _hover={{
            bgGradient: 'linear(to-r, brand.500, brand.700)',
          }}
        >
          Null ID
        </Text>
      </Flex>
    </Link>
  );
}
