import { Amplify } from 'aws-amplify';
import { fetchAuthSession } from '@aws-amplify/auth';
import { ResourcesConfig } from 'aws-amplify';

// Validate environment variables
const validateConfig = () => {
  const required = {
    VITE_USER_POOL_ID: import.meta.env.VITE_USER_POOL_ID?.trim(),
    VITE_USER_POOL_WEB_CLIENT_ID: import.meta.env.VITE_USER_POOL_WEB_CLIENT_ID?.trim(),
    VITE_REGION: import.meta.env.VITE_REGION?.trim()
  };

  const missing = Object.entries(required)
    .filter(([_, value]) => !value)
    .map(([key]) => key);

  if (missing.length > 0) {
    throw new Error(`Missing required environment variables: ${missing.join(', ')}`);
  }
};

// Configure Amplify with proper auth setup
const awsConfig: ResourcesConfig = {
  Auth: {
    Cognito: {
      userPoolId: import.meta.env.VITE_USER_POOL_ID!,
      userPoolClientId: import.meta.env.VITE_USER_POOL_WEB_CLIENT_ID!,
      signUpVerificationMethod: 'code',
    }
  },
  API: {
    REST: {
      subscriptionApi: {
        endpoint: 'https://presidio-api.null-id.com',
        region: import.meta.env.VITE_REGION!
      }
    }
  }
};

console.log('Amplify Config:', {
  userPoolId: import.meta.env.VITE_USER_POOL_ID,
  region: import.meta.env.VITE_REGION,
  apiEndpoint: awsConfig.API?.REST?.subscriptionApi.endpoint
});

// Helper to get current auth session
export const getAuthSession = async () => {
  try {
    return await fetchAuthSession();
  } catch (error) {
    console.error('Error getting auth session:', error);
    return null;
  }
};

// Helper to get current auth token
export const getIdToken = async () => {
  const session = await getAuthSession();
  return session?.tokens?.idToken?.toString();
};

// Configure Amplify with validation
export const configureAmplify = () => {
  validateConfig();
  Amplify.configure(awsConfig);
};

// Initialize configuration
configureAmplify();