import React, { Suspense } from 'react';
import {
  Box,
  Center,
  Container,
  Heading,
  Spinner,
  Text,
  VStack,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  useColorModeValue,
} from '@chakra-ui/react';
import { useAuth } from '../hooks/useAuth';

const DataGenerator = React.lazy(() => import('../components/DataGeneration/DataGenerator'));
const FileProcessor = React.lazy(() => import('../components/FileProcessing/FileProcessor'));
const TestGenerator = React.lazy(() => import('../pages/TestGenerator'));

const LoadingFallback = () => (
  <Center p={8}>
    <Spinner size="xl" color="blue.400" />
  </Center>
);

export default function Dashboard() {
  const { isAuthenticated, isLoading } = useAuth();
  const bgColor = useColorModeValue('white', 'gray.800');
  const cardBg = useColorModeValue('white', 'gray.700');
  const textColor = useColorModeValue('gray.600', 'gray.300');
  const headingColor = useColorModeValue('gray.800', 'white');
  const tabBg = useColorModeValue('white', 'gray.800');
  const contentBg = useColorModeValue('gray.50', 'gray.900');

  if (isLoading) {
    return (
      <Center height="100vh">
        <Spinner size="xl" color="blue.400" />
      </Center>
    );
  }

  if (!isAuthenticated) {
    return null;
  }

  return (
    <Box minH="100vh" bg={bgColor}>
      <Container maxW="container.xl" py={8}>
        <VStack spacing={8} align="stretch">
          <Box>
            <Heading size="xl" color={headingColor} mb={4}>
              Your Privacy Dashboard
            </Heading>
            <Text fontSize="lg" color={textColor}>
              Manage your data processing and analysis tools with our comprehensive privacy suite
            </Text>
          </Box>

          <Box bg={contentBg} p={8} rounded="lg" shadow="lg">
            <Tabs variant="enclosed" colorScheme="blue">
              <TabList>
                <Tab _selected={{ bg: tabBg, borderColor: 'blue.400' }}>Data Generation</Tab>
                <Tab _selected={{ bg: tabBg, borderColor: 'blue.400' }}>File Processing</Tab>
                <Tab _selected={{ bg: tabBg, borderColor: 'blue.400' }}>Test Generator</Tab>
              </TabList>

              <TabPanels>
                <TabPanel bg={cardBg} rounded="md" shadow="sm" mt={4}>
                  <Suspense fallback={<LoadingFallback />}>
                    <DataGenerator />
                  </Suspense>
                </TabPanel>

                <TabPanel bg={cardBg} rounded="md" shadow="sm" mt={4}>
                  <Suspense fallback={<LoadingFallback />}>
                    <FileProcessor />
                  </Suspense>
                </TabPanel>

                <TabPanel bg={cardBg} rounded="md" shadow="sm" mt={4}>
                  <Suspense fallback={<LoadingFallback />}>
                    <TestGenerator />
                  </Suspense>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </VStack>
      </Container>
    </Box>
  );
}
