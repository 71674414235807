import React from 'react';
import {
  Box,
  Container,
  Link,
  SimpleGrid,
  Stack,
  Text,
  Heading,
  useColorModeValue,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

const Footer: React.FC = () => {
  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const textColor = useColorModeValue('gray.600', 'gray.400');
  const headingColor = useColorModeValue('gray.700', 'white');

  return (
    <Box
      bg={bgColor}
      color={textColor}
      py={10}
    >
      <Container maxW="container.xl">
        <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing={8}>
          <Stack spacing={4}>
            <Heading
              as="h4"
              size="md"
              color={headingColor}
            >
              Null-ID
            </Heading>
            <Text>
              Secure data anonymization for modern applications
            </Text>
            <Text>
              <Link href="mailto:privacy@null-id.com">privacy@null-id.com</Link>
            </Text>
          </Stack>

          <Stack spacing={4}>
            <Heading
              as="h4"
              size="md"
              color={headingColor}
            >
              Resources
            </Heading>
            <Stack>
              <Link as={RouterLink} to="/docs">Documentation</Link>
              <Link as={RouterLink} to="/api">API Reference</Link>
              <Link as={RouterLink} to="/blog">Blog</Link>
            </Stack>
          </Stack>

          <Stack spacing={4}>
            <Heading
              as="h4"
              size="md"
              color={headingColor}
            >
              Company
            </Heading>
            <Stack>
              <Link as={RouterLink} to="/about">About Us</Link>
              <Link as={RouterLink} to="/contact">Contact</Link>
              <Link as={RouterLink} to="/faq">FAQ</Link>
            </Stack>
          </Stack>

          <Stack spacing={4}>
            <Heading
              as="h4"
              size="md"
              color={headingColor}
            >
              Legal
            </Heading>
            <Stack>
              <Link as={RouterLink} to="/privacy">Privacy Policy</Link>
              <Link as={RouterLink} to="/terms">Terms of Service</Link>
            </Stack>
          </Stack>
        </SimpleGrid>

        <Box
          borderTopWidth={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          pt={8}
          mt={8}
          textAlign="center"
        >
          <Text> {new Date().getFullYear()} Null-ID. All rights reserved.</Text>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
