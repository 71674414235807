import {
  Box,
  Button,
  Container,
  Heading,
  Icon,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { FiShield, FiSettings, FiLayers, FiCode, FiDatabase, FiCopy } from 'react-icons/fi';

const features = [
  {
    icon: FiShield,
    title: 'Complete Data Privacy Solution',
    description: 'From anonymizing datasets to enabling encrypted communication, Null-ID offers a holistic privacy ecosystem that protects sensitive information at every level.'
  },
  {
    icon: FiSettings,
    title: 'Privacy Law Compliance',
    description: 'Meet regulatory requirements like GDPR, HIPAA, POPIA, and CCPA with customizable anonymization policies and secure tools built for modern privacy needs.'
  },
  {
    icon: FiLayers,
    title: 'Compliant Data for LLM Models',
    description: 'Generate privacy-compliant datasets for Large Language Models (LLMs) and other AI applications, ensuring compliance with privacy laws and regulations.'
  },
  {
    icon: FiCode,
    title: 'Developer-First Integration',
    description: 'Integrate seamlessly with your existing workflows using our robust API, CLI tools, and open-source libraries—designed to give developers complete control.'
  },
  {
    icon: FiDatabase,
    title: 'Enterprise-Ready Performance',
    description: 'Handle datasets and communication at any scale, with reliable, secure infrastructure designed for enterprise demands.'
  },
  {
    icon: FiCopy,
    title: 'Synthetic Data Generation',
    description: 'Generate privacy-compliant synthetic datasets that mimic real data for testing, analysis, and research, all while eliminating privacy risks.'
  }
];

export const LandingPage = () => {
  const bgColor = useColorModeValue('white', 'gray.800');
  const cardBg = useColorModeValue('white', 'gray.700');
  const textColor = useColorModeValue('gray.600', 'gray.300');
  const headingColor = useColorModeValue('gray.800', 'white');

  return (
    <Box minH="100vh" bg={bgColor}>
      
      {/* Hero Section */}
      <Container maxW="container.xl" pt={20} pb={16}>
        <Stack
          direction={{ base: 'column', lg: 'row' }}
          spacing={{ base: 8, lg: 20 }}
          align="center"
          justify="space-between"
        >
          <VStack flex={1} spacing={6} align="flex-start">
            <Heading
              as="h1"
              size="2xl"
              lineHeight="shorter"
              color={headingColor}
            >
              Your Privacy Platform for a{' '}
              <Text
                as="span"
                bgGradient="linear(to-r, blue.400, blue.600)"
                bgClip="text"
              >
                Secure Digital World
              </Text>
            </Heading>
            <Text fontSize="xl" color={textColor}>
              Transform sensitive data and communications into secure, private interactions effortlessly. 
              Build trust and ensure compliance with privacy laws—all while maintaining utility and security.
            </Text>
            <Stack direction={{ base: 'column', sm: 'row' }} spacing={4}>
              <Button
                size="lg"
                colorScheme="blue"
                px={8}
                onClick={() => window.location.href = '/register'}
              >
                Get Started Free
              </Button>
              <Button
                size="lg"
                variant="outline"
                px={8}
                onClick={() => window.location.href = '/docs'}
              >
                View Documentation
              </Button>
            </Stack>
          </VStack>
        </Stack>
      </Container>

      {/* Features Section */}
      <Box bg={useColorModeValue('gray.50', 'gray.900')} py={20}>
        <Container maxW="container.xl">
          <VStack spacing={12}>
            <Box textAlign="center" maxW="container.md" mx="auto">
              <Heading as="h2" size="xl" mb={4}>
                A Comprehensive Privacy Ecosystem
              </Heading>
              <Text fontSize="lg" color={textColor}>
                Data anonymization, secure communication, and privacy-first tools for individuals and organizations that value their data integrity.
              </Text>
            </Box>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
              {features.map((feature, index) => (
                <Box
                  key={index}
                  bg={cardBg}
                  p={8}
                  rounded="lg"
                  shadow="lg"
                  _hover={{
                    transform: 'translateY(-4px)',
                    transition: 'transform 0.3s ease-in-out'
                  }}
                >
                  <VStack spacing={4} align="flex-start">
                    <Icon as={feature.icon} w={8} h={8} color="blue.400" />
                    <Heading as="h3" size="md">
                      {feature.title}
                    </Heading>
                    <Text color={textColor}>{feature.description}</Text>
                  </VStack>
                </Box>
              ))}
            </SimpleGrid>
          </VStack>
        </Container>
      </Box>

      {/* CTA Section */}
      <Box bg="blue.600" color="white" py={20}>
        <Container maxW="container.xl">
          <VStack spacing={8} textAlign="center">
            <Heading as="h2" size="xl">
              Ready to Take Control of Your Privacy?
            </Heading>
            <Text fontSize="lg" maxW="container.md">
              Join organizations and privacy-conscious individuals who trust Null-ID to safeguard their data 
              and communications while staying ahead of compliance requirements.
            </Text>
            <Button
              size="lg"
              colorScheme="whiteAlpha"
              px={8}
              onClick={() => window.location.href = '/register'}
            >
              Try Null-ID Today
            </Button>
          </VStack>
        </Container>
      </Box>
    </Box>
  );
};
